// this library is used to set up nice datetime picker on forms
// documentation & demos here => https://flatpickr.js.org/

document.addEventListener("turbolinks:load", () => {
  flatpickr(".datetimepicker", {
    enableTime: true,
    time_24hr: true,
    minuteIncrement: 1,
    altInput: true,
    altFormat: "Y-m-d H:i",
    dateFormat: 'Y-m-d H:i'
  });
})
