import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  update(event) {
    Rails.fire(this.element, 'submit');

    this.element.closest('.operator').classList.add("saved");
  }
}
