import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  static targets = [ 'row', 'link' ]

  onSuccess(event) {
    const [data, ,] = event.detail;
    const message = data.message;

    this.showResponseMessage(message, 'table-success')
    this.hideLink()
  }

  onError(event) {
    this.showResponseMessage('Error Occured! Please try again later.', 'table-danger')
  }

  showResponseMessage(msg, klass) {
    this.rowTarget.classList.add(klass)

    var linkElement = this.linkTarget;

    if(linkElement.nextSibling) {
      linkElement.parentNode.removeChild(linkElement.nextSibling)
    }

    linkElement.insertAdjacentHTML("afterend", "<div>" + msg + "</div>");
  }

  hideLink() {
    this.linkTarget.style.display = "none"
  }
}
