import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  static targets = [ 'fromDate', 'toDate' ]

  connect() {
    var fromDate = flatpickr(this.fromDateTarget, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: 'Y-m-d',
      showMonths: 1,
      maxDate: this.toDateTarget.value
    });

    var toDate = flatpickr(this.toDateTarget, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: 'Y-m-d',
      showMonths: 1,
      minDate: this.fromDateTarget.value,
      maxDate: new Date()
    });
  }

  updateFromDatePicker() {
    const fromDatePicker = this.fromDateTarget._flatpickr
    fromDatePicker.set('maxDate', this.toDateTarget.value)
  }

  updateToDatePicker() {
    const toDatePicker = this.toDateTarget._flatpickr
    toDatePicker.set('minDate', this.fromDateTarget.value)
  }
}
