// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
global.$ = jQuery;
window.jQuery = jQuery;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "@fortawesome/fontawesome-pro/js/all"
import "bootstrap"
import "../stylesheets/application"
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

require('packs/helpers.js')
require('custom/form_validation.js')
require('custom/flatpickr.js')
