import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  static targets = [ 'switchElement', 'row' ]
  static values = { 
    activateUrl: String, 
    deactivateUrl: String, 
    rootUrl: String, 
    bastionRootUrl: String
  }

  async toggle_object_state() {
    const request = new Request("PUT", this.buildUrl())
    const response = await request.perform()

    if (response.ok) {
      this.showResponseMessage('Success!', 'table-success')
    } else {
      // On error, reset checkbox state back to old ones
      this.switchElementTarget.checked = !this.switchElementTarget.checked

      this.showResponseMessage('Error!', 'table-danger')
    }
  }

  buildUrl() {
    if (this.switchElementTarget.checked) {
      return this.activateUrlValue
    } else {
      return this.deactivateUrlValue
    }
  }

  showResponseMessage(msg, klass) {
    this.rowTarget.classList.add(klass)

    var switchElement = this.switchElementTarget

    if(switchElement.nextSibling) {
      switchElement.parentNode.removeChild(switchElement.nextSibling)
    }

    switchElement.insertAdjacentHTML("afterend", "<div>" + msg + "</div>")
  }

  toggleAppVersion() {
    var url 

    if(this.switchElementTarget.checked) {
      url = this.bastionRootUrlValue
    } else {      
      url = this.rootUrlValue
    }

    window.location.href = url
  }
}
