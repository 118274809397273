import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.load();
  }

  load() {
    if (!this.urlValue) {return}

    const request = new Request("GET", this.urlValue)
    request.perform()
      .then(response => response.html)
      .then(html => this.element.innerHTML = html)
  }

  assign(event) {
    const request = new Request("PUT", this.build_url(event.currentTarget))
    request.perform()
      .then(response => response.html)
      .then(html => this.element.innerHTML = html)
  }

  build_url(clicked) {
    const operatorId = clicked.dataset["operatorId"]
    const parkingLotId = clicked.value
    const url_suffix = clicked.dataset["urlSuffix"]
    const isBastion = clicked.dataset["isBastion"]

    let urlPrefix;

    if (isBastion) {
      urlPrefix = '/bastion/operators'
    } else {
      urlPrefix = '/operators'
    }

    return `${urlPrefix}/${operatorId}/parking_lots/${parkingLotId}/${url_suffix}`
  }
}
