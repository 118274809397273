import { Controller } from "stimulus"
import { Request } from "@rails/request.js"

export default class extends Controller {
  static targets = [ 
    'accessType', 'countryAndRegionFields',  'countryCode', 'regionField' 
  ]

  initialize() {
    this.showOrHideRegion()
  }

  showOrHideCountryAndRegion() {
    if (this.accessTypeTarget.value === 'LPN') {
      this.countryAndRegionFieldsTarget.style.display = ''
    } else {
      this.countryAndRegionFieldsTarget.style.display = 'none'
    }
  }

  showOrHideRegion() {
    if (this.countryCodeTarget.value === 'DEU' || this.countryCodeTarget.value === 'GBR') {
      this.regionFieldTarget.style.display = ''
    } else {
      this.regionFieldTarget.style.display = 'none'
    }
  }
}
