function removeValidationErrors(form) {
  form.find('.alert.alert-danger').remove()
  form.find('.invalid-feedback').remove()
  form.find('.is-invalid').removeClass('is-invalid').addClass('is-valid')
}
function addValidationError(input, messages){
  addClassUnlessExist(input, 'is-invalid')
  var wrapper = input.parent()
  for(var message in messages) {
    wrapper.append($("<div class='invalid-feedback'>" + messages[message] + "</div>"))
  }
}
function updateFormWithValidationErrors(form, message, errors){
  removeValidationErrors(form)

  for(var attribute in errors) {
    var input;

    const el = form.find(":visible:input[name*='[" + attribute + "]']");

    if(el.length > 0) {
      input = $(el.last())

      if(input && !input.length) {
        // Find select input
        input = $(form.find(":visible:select[name*='[" + attribute + "]']").last())

        if(!input.length) {
          // flatpickr makes actual input tag hidden and creates a new input tag
          // that is visible to user, therefore datetimepicker inputs treated in
          // different manner
          input = $(form.find(":input.datetimepicker[name*='[" + attribute + "]']").last())

          if(input.length) {
            input.next().addClass('is-invalid')
          }
        }
      }
      addValidationError(input, errors[attribute])
    } else {
      form.prepend($(`<div class="alert alert-danger">${attribute}: ${errors[attribute]}</div>`))
    }
  }
  form.prepend($('<div class="alert alert-danger">' + message + '</div>'))
}

$(document).on('turbolinks:load', function() {
  $('[data-behavior="json-form"').on("ajax:success", function(e, data, status, xhr) {
    return;
  }).on("ajax:error", function(e) {
    if(e.detail[2].status === 422) {
      updateFormWithValidationErrors($(e.target), e.detail[0].message, e.detail[0].errors)
    }
    return;
  });
});
